import React from "react";
import styled from "styled-components";

import a5 from "../assets/campaign/5.jpeg";
import a1 from "../assets/campaign/1.jpeg";
import a2 from "../assets/campaign/2.jpeg";
import a3 from "../assets/campaign/3.jpeg";
import a4 from "../assets/campaign/4.jpeg";

import a6 from "../assets/campaign/6.jpeg";
import a7 from "../assets/campaign/7.jpeg";
import a8 from "../assets/campaign/8.jpeg";
import a9 from "../assets/campaign/9.jpeg";
import a10 from "../assets/campaign/10.jpeg";
import a11 from "../assets/campaign/11.jpeg";
import a12 from "../assets/campaign/12.jpeg";
import a13 from "../assets/campaign/13.jpeg";
import a14 from "../assets/campaign/14.jpeg";
import a15 from "../assets/campaign/15.jpeg";
import a16 from "../assets/campaign/16.jpeg";
import a17 from "../assets/campaign/17.jpeg";

import a19 from "../assets/nagari/3.jpeg";
import a20 from "../assets/nagari/4.jpeg";
import a21 from "../assets/nagari/5.jpeg";

import { ReactPhotoCollage } from "react-photo-collage";

const setting = {
  width: "100%",
  height: ["250px", "170px"],
  layout: [1, 4],
  photos: [
    { source: a4 },
    { source: a3 },
    { source: a5 },
    { source: a1 },
    { source: a2 },
    { source: a6 },
    { source: a7 },
    { source: a9 },
    { source: a10 },
    { source: a11 },
    { source: a12 },
    { source: a13 },
    { source: a14 },
    { source: a15 },
    { source: a16 },
    { source: a17 },
    { source: a8 },
    { source: a19 },
    { source: a20 },
    { source: a21 },
  ],
};

const Collage = ({ images }) => {
  return <ReactPhotoCollage {...setting} />;
};

export default Collage;
