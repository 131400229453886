import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const activeStyle = css`
  color: #d41e44;
`;

export const StyledLink = styled(Link)`
  &:link,
  &:visited,
  &:active {
    color: #7c859b;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    font-size: 18px;
    ${({ active }) => (active ? activeStyle : null)}
  }

  &:hover {
    color: #0c1a3c;
  }
`;
