import React, { useEffect, useState } from "react";
import RoutesComp from "./Routes";
import { StyledLink } from "./utils/styles";
import {
  Facebook,
  Instagram,
  Menu,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import rPra from "./assets/rp-logo.png";
import insta from "./assets/insta.webp";
import pla from "./assets/pla1.webp";

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 80px;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const LogoContainer = styled.div`
  p {
    font-size: 1rem;
    font-family: "Libre Baskerville", serif;
    font-weight: 600;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5.4rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

const NavigationLink = styled(StyledLink)`
  color: #0c1a3c;
`;
const BurgerIcon = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;

const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

const IconContainer = styled.div`
  padding: 4px;
  border: 1px solid #aaa;
  color: #7c859b;
  font-size: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #d41e44;
    border-color: #d41e44;
    color: #fff;
  }
`;

const FooterContainer = styled.div`
  background-color: #0d1939;
  padding: 2rem;
  text-align: center;
  color: #fff;
  margin-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const AppContainer = styled.div`
  /* Add any global styles here */
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  background-color: white;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 24px;
`;

const NavBar = () => {
  const location = useLocation();
  const ROUTES = [
    { label: "About Me", path: "/about-me" },
    { label: "Manifesto", path: "/manifesto" },
    { label: "Contact", path: "/contact" },
    { label: "Donate", path: "/donate" },
    { label: "Gallery", path: "/gallery" },
  ];

  const SOCIAL_MEDIA = [
    {
      link: "https://www.instagram.com/mohanbabusws?igsh=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr",
      icon: <Instagram style={{ fontSize: "1rem" }} />,
    },
    {
      link: "https://wa.me/919945563371",
      icon: <WhatsApp style={{ fontSize: "1rem" }} />,
    },
    {
      link: "https://www.facebook.com/mohan.babu.123829?mibextid=2JQ9oc",
      icon: <Facebook style={{ fontSize: "1rem" }} />,
    },
  ];

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  useEffect(() => {
    setShowMobileMenu(false);
  }, [location.pathname]);

  return (
    <HeaderContainer>
      <LogoContainer>
        <p style={{ marginBottom: "4px", fontSize: "14px" }}>
          Nagari. Mohanbabu Mudaliar
        </p>
        <p style={{ fontSize: "8px" }}>D.M.E, B.E, (M.E.S EUROPE)</p>
      </LogoContainer>
      <NavigationContainer>
        <StyledLink style={{ color: "#0c1a3c" }} to={`/`}>
          Home
        </StyledLink>
        {ROUTES.map((route, i) => (
          <NavigationLink key={i} to={`${route.path}`}>
            {route.label}
          </NavigationLink>
        ))}
      </NavigationContainer>
      <BurgerIcon onClick={toggleMobileMenu}>
        <Menu />
      </BurgerIcon>
      <SocialMediaContainer>
        {SOCIAL_MEDIA.map((curr, i) => (
          <StyledLink to={curr.link}>
            <IconContainer key={i}>{curr.icon}</IconContainer>
          </StyledLink>
        ))}
      </SocialMediaContainer>
      <MobileMenu show={showMobileMenu}>
        <StyledLink style={{ color: "#0c1a3c" }} to={`/`}>
          Home
        </StyledLink>
        {ROUTES.map((route, i) => (
          <NavigationLink key={i} to={`${route.path}`}>
            {route.label}
          </NavigationLink>
        ))}
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          {SOCIAL_MEDIA.map((curr, i) => (
            <StyledLink to={curr.link}>
              <IconContainer key={i}>{curr.icon}</IconContainer>
            </StyledLink>
          ))}
        </div>
      </MobileMenu>
    </HeaderContainer>
  );
};

const DevBy = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <DevBy style={{}}>
        <p style={{}}>Website developed by R. Prabhu </p>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ height: "30px", width: "30px", cursor: "pointer" }}>
            <img
              src={rPra}
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </div>
          <a
            href="https://www.instagram.com/r._prabhu_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
          >
            <div style={{ height: "30px", width: "30px", cursor: "pointer" }}>
              <img
                src={insta}
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/dev?id=7280329875793392282&hl=en_IN&gl=US"
            target="_blank"
          >
            <div style={{ height: "30px", width: "30px", cursor: "pointer" }}>
              <img
                src={pla}
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            </div>
          </a>
        </div>
      </DevBy>

      <p>© copyright 2021 by nagari.co.in</p>
    </FooterContainer>
  );
};

const App = () => {
  return (
    <AppContainer>
      <NavBar />
      <RoutesComp />
      <Footer />
    </AppContainer>
  );
};

export default App;
