import React, { useEffect } from "react";
import { ReactTyped } from "react-typed";
import CountingAnimation from "../../components/CountingAnimation";
import styled from "styled-components";
import cta from "../../assets/cta-1-1-bg.jpg";
import {
  Apartment,
  Book,
  EnergySavingsLeafRounded,
  HealthAndSafety,
  Money,
  Star,
} from "@mui/icons-material";
import HeroImg from "../../assets/nagari/1-removebg.png";
import HeroImg2 from "../../assets/nagari/2.jpeg";

const HeroCont = styled.div`
  height: calc(100vh);
  background-color: #0d1939;
  padding: 8rem;
  padding-top: 16rem;
  background-image: url(${HeroImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;

  @media (max-width: 600px) {
    padding: 1rem;
    padding-top: 16rem;
  }
`;

const WeCan = styled.h1`
  font-size: 42px;
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

const HeroText = styled.div`
  @media (max-width: 600px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    background-color: #0d1939;
  }
`;

const HeroContContent = styled.div`
  font-size: 38px;
  color: white;
  display: flex;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
const Para = styled.p`
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
const HeadingCont = styled.h1`
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;
const Home = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Hero />
      <PotisenPolitic />
      {/* <CampainCounter /> */}

      <Cta />
      <Principles />
    </div>
  );
};

export default Home;

const Hero = () => {
  return (
    <HeroCont style={{}}>
      <HeroContContent>
        <HeroText>
          <HeadingCont>Let's Move</HeadingCont>
          <HeadingCont style={{ marginBottom: "42px" }}>
            Nagari{" "}
            <ReactTyped
              loop
              backSpeed={50}
              onBegin={function noRefCheck() {}}
              onComplete={function noRefCheck() {}}
              onDestroy={function noRefCheck() {}}
              onLastStringBackspaced={function noRefCheck() {}}
              onReset={function noRefCheck() {}}
              onStart={function noRefCheck() {}}
              onStop={function noRefCheck() {}}
              onStringTyped={function noRefCheck() {}}
              onTypingPaused={function noRefCheck() {}}
              onTypingResumed={function noRefCheck() {}}
              strings={["Powerful", "Forward"]}
              typeSpeed={50}
              typedRef={function noRefCheck() {}}
            />
          </HeadingCont>
          <Para>Become a part of our campaign.</Para>
        </HeroText>
      </HeroContContent>
    </HeroCont>
  );
};

const PotisenPoliticCont = styled.div`
  padding: 10rem;
  padding-bottom: 0;

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

const PotisenPoliticContent = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const PotisenPolitic = () => {
  const POINTS = [
    "Lorem ipsum is simply available.",
    "Awesome ImageThe majority have suffered alteration.",
    "Awesome ImageDon't look even slightly.",
    "Awesome ImageIf you are going to use a passage.",
    "Awesome ImageYou need to sure there embarrassing.",
  ];
  return (
    <PotisenPoliticCont>
      <PotisenPoliticContent>
        <div style={{ flex: 1 }}>
          {/* <p
            style={{
              color: "#d41e44",
              marginBottom: "20px",
              fontSize: "18px",
              fontWeight: "800",
            }}
          >
            Nagari
          </p> */}
          <WeCan
            style={{
              fontFamily: "'Libre Baskerville', serif",

              marginBottom: "10px",
            }}
          >
            We can build better future together
          </WeCan>
          {/* <ul style={{ listStyle: "none" }}>
            {POINTS.map((curr) => {
              return (
                <li style={{ marginBottom: "14px", color: "#7c859b" }}>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <div>&#x2714;</div> <div>{curr}</div>
                  </div>
                </li>
              );
            })}
          </ul> */}
          <p>
            Absolutely! Building a better future requires collaboration,
            innovation, and a shared vision for progress. By working together,
            pooling our resources, knowledge, and expertise, we can address
            global challenges, create more inclusive societies, and develop
            sustainable solutions for the benefit of all. It's through
            collective effort and cooperation that we can pave the way for a
            brighter tomorrow.
          </p>
        </div>
        <div
          style={{ flex: 1, backgroundColor: "#7c859b", overflow: "hidden" }}
        >
          <img src={HeroImg2} style={{ width: "100%", height: "auto" }} />
        </div>
        <div
          style={{
            flex: 1,
            backgroundColor: "#d41e44",
            color: "#fff",
            padding: "2rem",
          }}
        >
          <h1 style={{ marginBottom: "20px" }}>Vote Status</h1>

          <p style={{ lineHeight: "1.8", fontSize: "18px" }}>
            By combining our strengths, resources, and talents, we can overcome
            obstacles, achieve ambitious goals, and create a future that is
            prosperous, sustainable, and equitable for all. Together, we can
            harness technology, innovation, and human potential to address
            pressing challenges, unlock new opportunities, and build a world
            that benefits everyone.
          </p>
        </div>
      </PotisenPoliticContent>
    </PotisenPoliticCont>
  );
};

const CampaignCounterCont = styled.div`
  padding: 10rem;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 6rem 1rem;
  }
`;

const CampainCounter = () => {
  return (
    <CampaignCounterCont>
      <div
        style={{
          height: "1px",
          backgroundColor: "#ddd",
          width: "100%",
          marginBottom: "10px",
        }}
      />
      <div
        style={{
          height: "1px",
          backgroundColor: "#ddd",
          width: "100%",
          marginBottom: "60px",
        }}
      />
      <CountingAnimation
        target={48754}
        duration={2000}
        fontSize="80px"
        color="#d41e44"
        fontWeight="bold"
      />
      <h1
        style={{ fontWeight: "400", fontFamily: "'Libre Baskerville', serif" }}
      >
        People have joined the campaigns
      </h1>
    </CampaignCounterCont>
  );
};

const BackgroundContainer = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-image: linear-gradient(
      to bottom,
      rgba(13, 25, 57, 0.8) 0%,
      rgba(13, 25, 57, 0.8) 100%
    ),
    url(${cta});
  background-size: cover;
  background-repeat: no-repeat;

  padding: 8rem;
  padding-top: 16rem;
  font-family: "Libre Baskerville", serif;
  font-size: 38px;
  color: white;

  @media (max-width: 600px) {
    padding: 1rem;
    padding-top: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  z-index: 1;
  text-align: center;
  font-family: "Libre Baskerville", serif;
  font-size: 38px;
  color: white;
`;

const CtaHeading = styled.h1`
  max-width: 65%;

  @media (max-width: 600px) {
    font-size: 36px;
    max-width: 100%;
  }
`;

const Cta = () => {
  return (
    <BackgroundContainer style={{}}>
      {/* <Overlay /> */}
      <Content>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {/* Your content goes here */}

          <CtaHeading>Help us Bring the Change we Need</CtaHeading>
        </div>
      </Content>
    </BackgroundContainer>
  );
};

const PrincipleCont = styled.div`
  padding: 2rem;
  border: 1px solid #aaa;
  color: #7c859b;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  min-width: 200px;

  &:hover {
    background-color: #d41e44;
    border-color: #d41e44;
    color: #fff;
  }
`;

const PrinciplesCont = styled.div`
  padding: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 6rem 1rem;
  }
`;

const PrinciplesList = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Principles = () => {
  const PRINCIPLES = [
    { label: "Environment", icon: <EnergySavingsLeafRounded /> },
    { label: "Health Care", icon: <HealthAndSafety /> },
    { label: "Tax Returns", icon: <Money /> },
    { label: "Economy", icon: <Apartment /> },
    { label: "Education", icon: <Book /> },
  ];

  return (
    <PrinciplesCont style={{}}>
      <p
        style={{
          color: "#d41e44",
          marginBottom: "20px",
          fontSize: "18px",
          fontWeight: "800",
        }}
      >
        Nagari
      </p>
      <h1
        style={{
          maxWidth: "65%",
          fontFamily: "'Libre Baskerville', serif",
          fontSize: "38px",
        }}
      >
        Campaign Principles
      </h1>{" "}
      <PrinciplesList style={{}}>
        {PRINCIPLES.map((curr) => {
          return (
            <PrincipleCont>
              {curr.icon}
              {curr.label}
            </PrincipleCont>
          );
        })}
      </PrinciplesList>{" "}
      <p style={{ textAlign: "center" }}>
        How we can build a better country together!
      </p>
    </PrinciplesCont>
  );
};
