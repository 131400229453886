import React from "react";
import styled from "styled-components";
import { StyledLink } from "../../utils/styles";
import { Star } from "@mui/icons-material";

const Header = styled.div`
  background-color: #0d1939;
  margin: 0;
  padding: 8rem;
  padding-top: 16rem;

  @media (max-width: 600px) {
    padding: 6rem 1rem;
    padding-bottom: 2rem;
  }
`;

const HeaderHeading = styled.h1`
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;
const Manifesto = () => {
  const POINTS = [
    "Free Access to Portable Drinking Water (TDS 100<300 PPM).",
    "Woman welfare & empowerment.",
    "Free Medical services for all.",
    "Bio Gas Plants to villages & Waste to Energy Plants to municipalities.",
    "Implement Reduce, Reuse & Recycle System.",
    "Install STP (Sewage Treatment Plants) & ETP (Effluent Treatment Plants). Reuse STP & ETP treated water to develop Parks.",
    "Lake Developments & Improve Ground Water Levels.",
    "Eradicate Unemployment & Educate Self Employment.",
    "Implement of solar projects.",
    "Zero Corruption.",
    "Protect Environment.",
    "Achieve highest level of human rights and animal rights.",
    "Eradicate inequality.",
    "Increases Minimum Life Expectancy from 70.62 to 80 Years.",
    "Planting of minimum 2,00,000 (Two Lakh) saplings in Nagari constituenct alone",
    "Implementation of sports academy",
    "MLA funds utilisation will be transparent",
    "Will create MSP (Minimum support price) to farmers.",
  ];
  return (
    <div>
      <Header>
        <div
          style={{
            fontSize: "38px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <HeaderHeading style={{ textAlign: "center" }}>
            Manifesto
          </HeaderHeading>
          <StyledLink style={{ color: "#d41e44" }} to="/">
            Home
          </StyledLink>
        </div>
      </Header>
      <div style={{ padding: "1rem" }}>
        <h1 style={{ marginBottom: "1rem" }}>
          We can build better future together
        </h1>
        <div>
          {POINTS.map((curr) => {
            return (
              <div
                style={{ display: "flex", gap: "6px", marginBottom: "10px" }}
              >
                <div>
                  <Star style={{ fontSize: "14px", color: "gold" }} />
                </div>{" "}
                <div>{curr}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Manifesto;
