import React, { useEffect } from "react";
import SimpleMap from "../../components/Map";

import { StyledLink } from "../../utils/styles";
import styled from "styled-components";

const Header = styled.div`
  background-color: #0d1939;
  margin: 0;
  padding: 8rem;
  padding-top: 16rem;

  @media (max-width: 600px) {
    padding: 6rem 1rem;
    padding-bottom: 2rem;
  }
`;

const HeaderHeading = styled.h1`
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;
const Contact = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const mapSrc =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.0644868312164!2d-74.00597468507544!3d40.712776342762034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259c804e0a34d%3A0x4e4d35aa2e256647!2sNew%20York%2C%20NY!5e0!3m2!1sen!2sus!4v1644601221127!5m2!1sen!2sus";
  return (
    <div>
      <Header>
        <div
          style={{
            fontFamily: "'Libre Baskerville', serif",
            fontSize: "38px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <HeaderHeading>Contact</HeaderHeading>
          <StyledLink style={{ color: "#d41e44" }} to="/">
            Home
          </StyledLink>
        </div>
      </Header>
      <SimpleMap src={mapSrc} title="Google Map" />{" "}
    </div>
  );
};

export default Contact;
