import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import Vision from "./pages/Vision/Vision";
import Donate from "./pages/Donate/Donate";
import Manifesto from "./pages/Manifesto/Manifesto";

const RoutesComp = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/gallery" element={<AboutUs />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about-me" element={<Vision />} />
      <Route path="/donate" element={<Donate />} />
      <Route path="/manifesto" element={<Manifesto />} />

    </Routes>
  );
};

export default RoutesComp;
