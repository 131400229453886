import React, { useEffect } from "react";
import SimpleMap from "../../components/Map";
import { Email, FmdGood, Location, Phone } from "@mui/icons-material";
import { StyledLink } from "../../utils/styles";
import vision from "../../assets/vision.jpg";
import img2 from "../../assets/cta-1-1-bg.jpg";
import styled from "styled-components";
import a1 from "../../assets/nagari/3.jpeg"

const Header = styled.div`
  background-color: #0d1939;
  margin: 0;
  padding: 8rem;
  padding-top: 16rem;

  @media (max-width: 600px) {
    padding: 6rem 1rem;
    padding-bottom: 2rem;
  }
`;

const HeaderHeading = styled.h1`
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;
const ImageText = styled.div`
  margin: 8rem;
  padding: 40px;
  margin-bottom: 0;
  display: flex;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  gap: 24px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 4px;
    margin: 0;
    box-shadow: none;
  }
`;

const ImageText2 = styled.div`
  margin: 8rem;
  margin-bottom: 0;
  display: flex;
  border-radius: 4px;
  gap: 24px;
  @media (max-width: 600px) {
    flex-direction: column;
    margin: 1rem;
  }
`;

const Cont2 = styled.div`
  display: flex;
  padding: 40px 0;
  gap: 60px;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

const Vision = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const mapSrc =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.0644868312164!2d-74.00597468507544!3d40.712776342762034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259c804e0a34d%3A0x4e4d35aa2e256647!2sNew%20York%2C%20NY!5e0!3m2!1sen!2sus!4v1644601221127!5m2!1sen!2sus";
  return (
    <div>
      <Header>
        <div
          style={{
            fontFamily: "'Libre Baskerville', serif",
            fontSize: "38px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <HeaderHeading>About Me</HeaderHeading>
          <StyledLink style={{ color: "#d41e44" }} to="/">
            Home
          </StyledLink>
        </div>
      </Header>
      <ImageText style={{}}>
        <div style={{ overflow: "hidden", flex: 1 }}>
          <img
            src={a1}
            alt="vision"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            flex: 1,
          }}
        >
          <p>
            I am dedicated environmentalist on a mission to make the world a
            greener, more sustainable place for future generations. With a
            deep-rooted passion for nature and a commitment to conservation, I
            tirelessly advocate for environmental awareness and action.
          </p>
          <p>
            Through their work as an environmental activist, I strive to raise
            awareness about pressing environmental issues such as climate
            change, deforestation, and pollution. Armed with knowledge, empathy,
            and a desire for positive change, I educate and inspires others to
            take meaningful steps towards an eco-friendlier lifestyle.
          </p>
          <p>
            As a staunch advocate for biodiversity and ecosystem preservation, I
            actively participate in community clean-up efforts, tree planting
            initiatives. By leading by example and mobilizing grassroots
            movements, I empower individuals and communities to become stewards
            of the environment.
          </p>
          <p>
            In addition to their activism, I deeply involved in environmental
            research and policy advocacy, collaborating with organizations and
            policymakers to develop sustainable solutions and enact impactful
            legislation. With a focus on fostering harmony between humanity and
            nature, I work tirelessly to protect and preserve our planet's
            precious resources for generations to come.
          </p>
          <p>
            Join with me in the mission to create a healthier, more sustainable
            world, one small action at a time.
          </p>
        </div>
      </ImageText>
      <ImageText style={{}}>
        <div style={{ overflow: "hidden", flex: 1 }}>
        <img
            src={vision}
            alt="vision"
            style={{ height: "100%", width: "100%",marginTop:"3rem" }}
          />
        </div>
        <h1>Leadership  Vision</h1>
          <p>
            Vision is one of the core leadership values. It’s the dream and the direction for your company. It’s what forms your company culture, guides your decisions and helps you set – and crush – your goals. It’s what inspires others to follow you, work hard and make a difference. Leadership vision is what makes a company worth working for. 

No great leader ever accomplished anything without having a powerful vision, but that isn’t all you’ll need. You must not only get crystal-clear on your leadership vision. You must also be able to communicate it to others effectively and get them excited about it.
          </p>
      </ImageText>
   
       
    </div>
  );
};

export default Vision;
