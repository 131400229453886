import React, { useEffect } from "react";
import qr from "../../assets/donate.jpg";
import { StyledLink } from "../../utils/styles";
import styled from "styled-components";

const Header = styled.div`
  background-color: #0d1939;
  margin: 0;
  padding: 8rem;
  padding-top: 16rem;

  @media (max-width: 600px) {
    padding: 6rem 1rem;
    padding-bottom: 2rem;
  }
`;

const HeaderHeading = styled.h1`
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const ImageSize = styled.div`
  height: 500px;
  width: 500px;
  @media (max-width: 600px) {
    width: 240px;
    height: 240px;
  }
`;

const Para = styled.p`
  font-size: 24px;
  text-align: center;
  line-height: 1.8;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
const Donate = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header>
        <div
          style={{
            fontFamily: "'Libre Baskerville', serif",
            fontSize: "38px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <HeaderHeading>Donate</HeaderHeading>
          <StyledLink style={{ color: "#d41e44" }} to="/">
            Home
          </StyledLink>
        </div>
      </Header>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "4rem",
          gap: "20px",
        }}
      >
        <p>Please use the following QR Code to make donations</p>
        <ImageSize>
          <img
            style={{ width: "100%", height: "auto" }}
            src={qr}
            alt="qr for donation"
          />
        </ImageSize>
        <Para style={{}}>
          Or you can donate to the following UPI ID -{" "}
          <strong>9986009555@ibl</strong>
        </Para>

        <Para style={{}}>
          {" "}
          Or you can donate to the following Mobile No -{" "}
          <strong>+91 99860 09555</strong>
        </Para>
      </div>
    </div>
  );
};

export default Donate;
