import React from "react";
import styled from "styled-components";
import { Email, FmdGood, Phone } from "@mui/icons-material";
const MapContainer = styled.div`
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  position: relative;
`;

const AbsoluteCont = styled.div`
  position: absolute;
  bottom: 0;
  left: 100px;
  right: 100px;
  height: 100px;
  background-color: #d41e44;
  padding: 20px;

  @media (max-width: 600px) {
    left: 0;
    right: 0;
    padding: 4px;
    height: auto;
  }
`;

const Seperator = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const NumberLink = styled.a`
  font-size: 24px;
  color: white;
  &:link,
  &:visited,
  &:active {
    color: white;
    text-decoration: none;
  }
`;

const GoogleMap = ({ src, title }) => {
  return (
    <MapContainer>
      <iframe
        title={title}
        width="100%"
        height="100%"
        frameBorder="0"
        src={src}
        allowFullScreen
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
      <AbsoluteCont>
        <ContactContainer>
          <div
            style={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <FmdGood />
            <NumberLink>Nagari</NumberLink>
          </div>
          <Seperator
            style={{ width: "2px", backgroundColor: "#efefef", height: "40px" }}
          />
          <div
            style={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Phone />
            <NumberLink href="tel:9986009555">+91 99860 09555</NumberLink>
          </div>
          <Seperator
            style={{ width: "2px", backgroundColor: "#efefef", height: "40px" }}
          />
          <div
            style={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Email />
            <NumberLink href="mailto:mohanbabu05@gmail.com">
              mohanbabu05@gmail.com
            </NumberLink>
          </div>
        </ContactContainer>
      </AbsoluteCont>
    </MapContainer>
  );
};

export default GoogleMap;
