import React, { useEffect } from "react";
import { StyledLink } from "../../utils/styles";
import Collage from "../../components/CollageComp";
import styled from "styled-components";

const Header = styled.div`
  background-color: #0d1939;
  margin: 0;
  padding: 8rem;
  padding-top: 16rem;

  @media (max-width: 600px) {
    padding: 6rem 1rem;
    padding-bottom: 2rem;
  }
`;

const HeaderHeading = styled.h1`
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Content = styled.div`
  padding: 8rem;
  @media (max-width: 600px) {
    padding: 6rem 1rem;
  }
`;

const SubContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem;
  text-align: center;
  max-width: 65%;

  @media (max-width: 600px) {
    padding: 1rem;
    max-width: 100%;
  }
`;

const AboutUsHeading = styled.h1`
  max-width: 65%;
  margin-bottom: 16px;
  font-size: 48px;
  @media (max-width: 600px) {
    max-width: 100%;
    font-size: 24px;
  }
`;

const AboutUs = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <Header>
        <div
          style={{
            fontSize: "38px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <HeaderHeading style={{ textAlign: "center" }}>
           Gallery
          </HeaderHeading>
          <StyledLink style={{ color: "#d41e44" }} to="/">
            Home
          </StyledLink>
        </div>
      </Header>
      <Content>
        <Collage />
   
      </Content>
    </div>
  );
};

export default AboutUs;

// <div
// style={{
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }}
// >
// <SubContent style={{}}>
//   <p
//     style={{
//       color: "#d41e44",
//       marginBottom: "20px",
//       fontSize: "18px",
//       fontWeight: "800",
//     }}
//   >
//     About Nagari
//   </p>
//   {/* Your content goes here */}

//   <AboutUsHeading style={{}}>Get to Know Nagari</AboutUsHeading>
//   <p style={{ fontSize: "16px", marginBottom: "20px" }}>
//     There are many variations of passages of available but the
//     majority have suffered alteration in some form, by injected humou
//     or randomised words. Proin ac lobortis arcu, a vestibulum aug
//     ipsum neque, facilisis vel mollis vitae. Quisque aliquam dictum
//     condim.
//   </p>
// </SubContent>
// </div>