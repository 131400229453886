import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CountingAnimationWrapper = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  color: ${(props) => (props.color ? props.color : "#000")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  display: inline-block;
  font-family: "Libre Baskerville", serif;
`;

const CountingAnimation = ({
  target,
  duration,
  fontSize,
  color,
  fontWeight,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTimestamp;
    let animationFrameId;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * target));

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(step);
      }
    };

    animationFrameId = requestAnimationFrame(step);

    return () => cancelAnimationFrame(animationFrameId);
  }, [target, duration]);

  return (
    <CountingAnimationWrapper
      fontSize={fontSize}
      color={color}
      fontWeight={fontWeight}
    >
      {count}
    </CountingAnimationWrapper>
  );
};

export default CountingAnimation;
